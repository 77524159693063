import {
  Box,
  Flex,
  Text,
  Link,
  Image,
  Grid,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Spinner,
  useColorModeValue,
  useToast,
  Tooltip,
  Wrap,
  WrapItem,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  PopoverArrow,
  PopoverContent,
  IconButton,
  Divider
} from '@chakra-ui/react'
import {
  useGetProject,
  useFinishEditing,
  useGetReadyReport,
  useDelPhoto,
  useGetFolderList,
  useUpdateProjectPictures,
} from 'api/projects-api'
import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Card from 'components/card/Card'
import {
  AiOutlineUser,
  AiOutlinePhone
} from 'react-icons/ai'
import {MdAlternateEmail} from 'react-icons/md'
import {PiCursor} from 'react-icons/pi'
import {ArrowForwardIcon} from "@chakra-ui/icons";
import Upload from "../admin/profile/components/Upload";
import {FilePlaceholder} from "../../assets/img/resources/FilePlaceholder";
import {
  isProjectInDeliveredStatus,
  isProjectInTerminatedStatus,
  ManualEditingStatus,
  translateProjectStatus,
} from "../../constants";


function ManagePictures({getReadyReport, projectId}: {projectId: string, getReadyReport: Function}) {
  const timeout = useRef(null);

  const [
    {
      data: folderList,
      loading: getFolderListInProgress,
    },
    getFolderList,
  ] = useGetFolderList(projectId);

  const finishingAnImage = useCallback(
    () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(
        () => Promise.all([getReadyReport(), getFolderList()]).catch((_) => {}),
        500,
      );
    },
    [getReadyReport, getFolderList],
  );

  return <Flex flexDir='column' w='100%'>
    <Upload
      flexGrow={1}
      maxH='600px'
      projectId={projectId}
      finishinganimage={finishingAnImage}
    />
    {
      (
        getFolderListInProgress
        || folderList?.length > 0
      ) && <Flex
        flexGrow={1}
        h='fit-content'
        overflowY='auto'
        bg='white'
        borderRadius='20px'
        p='10px' m='20px' w='100%'
        maxH="600px"
        overflow='auto'
      >
        <Wrap
          direction='row'
          justify="center"
          py="20px"
          spacing={10}
        >
          {
            getFolderListInProgress
            && <Spinner />
          }
          {
            !getFolderListInProgress
            && folderList?.length > 0
            && folderList.map(
              (image: {name: string; id: string, thumbnailLink: string}, index: number) => (
                <WrapItem key={index}>
                  <DrivePhoto
                    name={image.name}
                    driveId={image.id}
                    th={image.thumbnailLink}
                    pid={projectId}
                  />
                </WrapItem>
              )
            )
          }
        </Wrap>
      </Flex>
    }
  </Flex>
}

function FinalImagesList (props: { projectId: string }) {
  const [
    {
      data: finalFolderList,
      loading: getFinalFolderListInProgress,
      error: getFinalFolderListError
    },
    getFinalFolderList,
  ] = useGetFolderList(props.projectId);

  useEffect(() => {
    getFinalFolderList().catch((_) => {})
  }, [])

  return (
    <Flex
      flexGrow={1}
      h='fit-content'
      overflowY='auto'
      bg='white'
      borderRadius='20px'
      p='10px' m='20px' w='100%'
      maxH='300px'
    >
      {
      getFinalFolderListInProgress &&
        <Spinner />
    }
      {
      getFinalFolderListError &&
        <Text color='red.300' fontWeight='bold'>
          {getFinalFolderListError.response?.data?.error_message ?? 'An error is occurred during get file list.'}
        </Text>
    }
      {
      finalFolderList &&
        <Wrap
          direction={{ base: 'column', sm: 'row' }}
          justify='center'
          py='20px'
          spacing={5}
        >
          {
          finalFolderList.map(
            (image: { name: string, id: string, thumbnailLink: string }) => <WrapItem key={image.id}>
              <DrivePhotoView th={image.thumbnailLink} name={image.name} />
            </WrapItem>
          )
        }
        </Wrap>
    }
    </Flex>
  )
}

function DrivePhotoView (props: { th: string, name: string }) {
  return (
    <>
      {
      props.th &&
        <Image
          fallback={
            <Flex alignItems='center' justifyContent='center' h='200px' w='200px'>
              <Spinner />
            </Flex>
        }
          alt={props.name}
          boxSize='200px'
          fit='cover'
          src={props.th}
        />
    }
      {
      !props.th &&
        <FilePlaceholder style={{ height: '200px' }} />
    }
    </>
  )
}

function DrivePhoto (props: { name?: string, driveId?: string, th: string, pid?: string }) {
  const [
    { loading: delPhotoInProgress },
    delPhoto
  ] = useDelPhoto(props.pid, props.driveId)
  const [deleted, setDeleted] = useState(false)
  const toast = useToast()

  const removePhoto = useCallback(
    () => {
      delPhoto()
        .then(() => setDeleted(true))
        .catch(
          () => toast({
            title: 'Error during deleting',
            status: 'error',
            isClosable: true
          })
        )
    },
    [delPhoto, toast]
  )

  return (
    <>
      {
      deleted &&
      null
    }
      {
      !deleted &&
      delPhotoInProgress &&
        <Spinner />
    }
      {
      !deleted &&
      !delPhotoInProgress &&
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label={`Photo: ${props.name}`}
              h='220px'
              p='10px'
              icon={
                <>
                  {
                  props.th &&
                    <Image
                      fallback={
                        <Flex alignItems='center' justifyContent='center' h='200px' w='200px'>
                          <Spinner />
                        </Flex>
                    }
                      alt={props.name}
                      boxSize='200px'
                      fit='cover'
                      src={props.th}
                    />
                }
                  {
                  !props.th &&
                    <FilePlaceholder style={{ height: '200px' }} />
                }
                </>
            }
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>Picture info</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Text>File name:</Text><Text fontWeight={700}>{props.name}</Text>
              <Button colorScheme='red' onClick={removePhoto}>Delete</Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
    }
    </>
  )
}

export default function ProjectDetailsPage() {
  const {projectId} = useParams();

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isOpenManagePictures,
    onOpen: onOpenManagePictures,
    onClose: onCloseManagePictures
  } = useDisclosure();

  const textColor = useColorModeValue('gray.700', 'white.300');
  const toast = useToast();

  const [
    {
      loading: updateProjectPicturesInProgress,
      error: updateProjectPicturesError,
    },
    updateProjectPictures,
  ] = useUpdateProjectPictures(projectId);

  const [
    {
      loading: finishEditingInProgress,
      error: finishEditingError
    },
    finishEditing
  ] = useFinishEditing(projectId)

  const [
    {
      data: readyReport,
      loading: getReadyReportInProgress,
      error: getReadyReportError
    },
    getReadyReport
  ] = useGetReadyReport(projectId)

  const [
    {
      data: projectData,
      loading: getProjectInProgress,
      error: getProjectError
    },
    getProjectAgain
  ] = useGetProject(projectId)

  const openFinishModal = useCallback(() => {
    getReadyReport()
      .then(onOpen)
      .catch((e) => {
        console.error(e);
        // doesn't matter
      });
  }, [getReadyReport, onOpen]);

  const openManageModal = useCallback(() => {
    getReadyReport()
      .then(onOpenManagePictures)
      .catch((e) => {
        console.error(e);
        // doesn't matter
      });
  }, [getReadyReport, onOpenManagePictures]);

  const handleFinishEditing = async () => {
    try {
      await finishEditing()
      await getProjectAgain()
      onClose()
    } catch (error) {
      console.error('Error during finishing editing:', error)
    }
  }

  const handleFinishManagePictures = async () => {
    try {
      await updateProjectPictures();
      await getProjectAgain();
      await getReadyReport();
      onCloseManagePictures();
    } catch (error) {
      console.error('Error during manage pictures:', error);
    }
  }

  useEffect(() => {
    if (getProjectError) {
      console.error('Error during loading project data:', getProjectError);
      toast({
        title: 'Cannot load project data',
        status: 'error',
        isClosable: true,
      });
    }
    if (finishEditingError) {
      console.error('Error during finishing editing:', finishEditingError)
      toast({
        title: 'Cannot finish editing',
        status: 'error',
        isClosable: true,
      });
    }
    if (updateProjectPicturesError) {
      console.error('Error during manage pictures:', updateProjectPicturesError);
      toast({
        title: 'Cannot update project',
        status: 'error',
        isClosable: true,
      });
    }
  }, [getProjectError, finishEditingError, updateProjectPicturesError]);

  return <>
    {
      projectData?.projectName
      && <Flex justifyContent="space-between" alignItems='center'>
        <Text as='b' ml='20px' fontSize='3xl'>Project: {projectData.projectName}</Text>

        {
          projectData.status === 'post-processing'
          && <Tooltip
            hasArrow
            label={
              getReadyReportError
                ? 'Error during checking'
                : getReadyReportInProgress
                  ? 'Checking...'
                  : readyReport.editedPicNumbers > 0
                    ? 'Ready to finish editing'
                    : 'Please check uploaded files.'
            }
          >
            <Button
              rightIcon={<ArrowForwardIcon/>}
              isLoading={getReadyReportInProgress}
              loadingText='Checking...'
              variant="photonMedium"
              width='200px'
              mr='50px'
              mt='10px'
              onClick={openFinishModal}
            >
              Finish editing
            </Button>
          </Tooltip>
        }
      </Flex>

    }
    <Box fontSize='20px'>
      {
        projectData
        && <Box>
          {
            projectData.driveLink
            && <Link isExternal href={projectData.driveLink}>
              <Button variant='photonDark' width='200px' m='20px'>
                Go to Drive folder
              </Button>
            </Link>
          }
          <Flex justifyContent='space-between' alignItems='center' ml='10px' mr='20px' direction='column'>
            <Flex w='100%'>
              <Flex
                justifyContent='center'
                p='10px 50px'
                alignItems='center' bg='white' borderRadius='20px' m='10px' w='50%'
              >
                <Text>{`Status: ${translateProjectStatus(projectData.status)}`}</Text>
              </Flex>
              <Flex
                justifyContent='center'
                textAlign='center'
                alignItems='center'
                bg='white'
                p='10px 50px'
                borderRadius='20px'
                m='10px'
                w='50%'
              >
                <Text>{projectData.isOpen}</Text>
              </Flex>
            </Flex>

            <Flex w='100%'>
              <Flex
                justifyContent='center'
                alignItems='center'
                bg='white'
                p='10px 50px'
                borderRadius='20px' m='10px'
                w='50%'
              >
                <Text>{`Client name: ${projectData.clientName}`}</Text>
              </Flex>
              <Flex
                justifyContent='center'
                alignItems='center' bg='white' borderRadius='20px' m='10px'
                p='10px 50px'
                w='50%'
              >
                <Text>{`Package name: ${projectData.packageName}`}</Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex>
            <Flex
              bg='white'
              justifyContent='space-between'
              alignItems='center'
              height='50px'
              borderRadius='20px'
              p='10px' m='20px' w='100%'
            >
              <Text fontSize='20px' fontWeight='bold'>Photographer:</Text>
              <Flex><AiOutlineUser size='30px'/><Text ml='10px'> {projectData.creative.name}</Text></Flex>
              <Flex><AiOutlinePhone size='30px'/><Text ml='10px'> {projectData.creative.phone}</Text> </Flex>
              <Flex> <MdAlternateEmail size='30px'/><Text ml='10px'>{projectData.creative.email}</Text></Flex>
            </Flex>
          </Flex>

          {
            !isProjectInDeliveredStatus(projectData?.status)
            && !isProjectInTerminatedStatus(projectData?.status)
            && projectData?.manualEditingStatus !== ManualEditingStatus.WAITING_FOR_EDITING
            && <ManagePictures projectId={projectId} getReadyReport={getReadyReport} />
          }

          {
            !isProjectInTerminatedStatus(projectData?.status)
            && projectData?.manualEditingStatus !== ManualEditingStatus.WAITING_FOR_EDITING
            && <Flex justifyContent='center' alignItems='center' bg='white' p='10px' borderRadius='20px' m='20px'>
              <Button
                width='fit-content'
                variant='photonDark'
                onClick={openManageModal}
              >
                Manage Pictures
              </Button>
            </Flex>
          }

          {
            isProjectInTerminatedStatus(projectData?.status)
            && <FinalImagesList projectId={projectId} />
          }

          <Flex>
            <Box w='40%'>
              <Flex justifyContent='center' alignItems='center' bg='white' p='10px' borderRadius='20px' m='20px'>
                  <Link isExternal href={projectData.editorBrief}>
                    <Text mr='10px'>
                      Editor brief and pricing
                    </Text>
                  </Link>
                  <PiCursor/>
                </Flex>
                <Flex justifyContent='center' alignItems='center' bg='white' p='10px' borderRadius='20px' m='20px'>
                  <Link isExternal href={projectData.brief}>
                    <Text mr='10px'>
                      Click here to read photographer brief
                    </Text>
                  </Link>
                  <PiCursor/>
                </Flex>
              {
                projectData.refFiles.length !== 0
                && <Card shadow='md' borderWidth='1px' m='20px' p='10px'>
                  <Text textAlign='center' fontSize='20px' fontWeight='bold'>{`Reference files:`}</Text>
                  {
                    projectData.refFiles.map(
                      (file: { name: string, url: string }) => <Flex>
                        <PiCursor/>
                        <Link href={file.url} isExternal> {file.name} </Link>
                      </Flex>
                    )
                  }
                </Card>
              }
              <Card shadow='md' borderWidth='1px' m='20px' p='5px'>
                <Table size="sm" variant="simple">
                  <Tbody>
                    <Tr>
                      <Th fontSize="16px">Created</Th>
                      <Td fontSize="20px" lineHeight='25px'>
                        {moment(projectData.createdAt).format('YYYY.MM.DD.\n HH:mm')}
                      </Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px">Shooting time</Th>
                      <Td fontSize="20px" lineHeight='25px'>
                        {moment(projectData.shootingTime).format('YYYY.MM.DD.\n HH:mm')}
                      </Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px">Delivery (editing) deadline</Th>
                      <Td fontSize="20px" lineHeight='25px'>
                        {moment(projectData.deliveryDeadline).format('YYYY.MM.DD.\n HH:mm')}
                      </Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px">Photographer's deadline</Th>
                      <Td fontSize="20px" width='150px' lineHeight='25px'>
                      {moment(projectData.editingDeadline).format('YYYY.MM.DD.\n HH:mm')}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Card>

              <Card shadow='md' borderWidth='1px' m='20px' p='5px'>
                <Table size="sm" variant="simple">
                  <Tbody>
                  <Tr>
                      <Th fontSize="16px" padding='10px 15px'>Exp. number of photos</Th>
                      <Td fontSize="20px" textAlign='center'>{projectData.expectedPhotoNumber || 0}</Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px" padding='10px 15px'>Pics uploaded by photographer</Th>
                      <Td fontSize="20px" textAlign='center'>{projectData.uploadedPhotosNumber}</Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px" padding='10px 15px'>Pics uploaded by me</Th>
                      <Td fontSize="20px" textAlign='center'>{projectData.uploadedPhotosByEditorNumber}</Td>
                    </Tr>
                       <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px" padding='10px 15px'>Editing price/ photo</Th>
                      <Td w='150px' fontSize="20px" textAlign='center'>
                        {`${projectData.manualEditingPrice?.amount ?? '-'} ${projectData.manualEditingPrice?.currency ?? ''}`}
                      </Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px" padding='10px 15px'>Editing price</Th>
                      <Td w='150px' fontSize="20px" textAlign='center'>
                        {`${projectData.manualEditingPriceSum?? '-'} ${projectData.manualEditingPrice?.currency ?? ''}`}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Card>

            </Box>


            <Card w='60%'>
              <Text fontWeight='bold'>Reference photos:</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap="5px" mt="10px">
                {
                  projectData.refPics.map(
                    (file: any) => <Flex key={file.id} direction="column" alignItems="center">
                      <Image src={file.url} alt={file.name} maxH="200px"/>
                    </Flex>
                  )
                }
              </Grid>
            </Card>
          </Flex>
          {
            projectData.comment
            && <Card w='100%'>
              <Text fontWeight='bold'>Comment:</Text>
              <Divider />
              <Text textAlign='justify'>{projectData.comment}</Text>
            </Card>
          }
          {
            projectData.creativeMessage
            && <Card w='100%'>
              <Text fontWeight='bold'>Photographer's message:</Text>
              <Divider />
              <Text textAlign='justify'>{projectData.creativeMessage}</Text>
            </Card>
          }
        </Box>
      }
      {
        getProjectInProgress
        && <Spinner/>
      }

      {/*error during loading data*/}
      {
        getProjectError
        && <Text color={textColor}>Error fetching data</Text>
      }

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Have you finished editing this project?</ModalHeader>
          <ModalCloseButton/>
          {
            readyReport
            && <ModalBody>
              <Text>
                {`You've uploaded ${readyReport.editedPicNumbers} images to the Google Drive folder.`}
              </Text>
              <Text as='b'>Please confirm you are completing this project!</Text>
            </ModalBody>
          }
          <ModalFooter>
            <Button
              variant='photonMedium'
              mr={3} onClick={onClose}>
              Back
            </Button>
            <Button
              width='fit-content'
              isLoading={finishEditingInProgress}
              variant='photonDark'
              onClick={handleFinishEditing}
            >
              Close this project
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenManagePictures} onClose={onCloseManagePictures}>
        <ModalOverlay/>
        <ModalContent minW='60vw'>
          <ModalHeader>Manage pictures {getReadyReportInProgress && <Spinner/>}</ModalHeader>
          <ModalCloseButton/>
          {
            readyReport
            && <ModalBody>
              <Text>
                {`You've uploaded ${readyReport.editedPicNumbers} images to the Google Drive folder.`}
              </Text>
              <ManagePictures projectId={projectId} getReadyReport={getReadyReport} />
            </ModalBody>
          }
          <ModalFooter>
            <Button
              variant='photonMedium'
              mr={3} onClick={onCloseManagePictures}>
              Back
            </Button>
            <Button
              width='fit-content'
              isLoading={updateProjectPicturesInProgress}
              variant='photonDark'
              onClick={handleFinishManagePictures}
            >
              Finish Manage Pictures
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  </>;
}
