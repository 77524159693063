import {
  Input as ChakraInput,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Text,
  useTheme
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'

interface InputProps {
  children: ReactNode
  error?: FieldError
  field: string
  inputStyles?: any
  leftAddon?: ReactNode
  rightAddon?: ReactNode
  placeholder?: string
  registerProps: UseFormRegisterReturn
  required?: boolean
  type?: string
}

const Input = ({
  children,
  error,
  field,
  inputStyles,
  leftAddon,
  rightAddon,
  placeholder,
  registerProps,
  required,
  type = 'text'
}: InputProps) => {
  const { colors } = useTheme()

  return (
    <FormControl
      id={field}
      isInvalid={!!error}
      variant={leftAddon ? 'photonFormWithLeftAddon' : 'photonForm'}
    >
      <FormLabel>
        {children}
        {
          required &&
            <Text as='span' style={{ color: colors.photonNeonPurple['100'] }}>
              {' '}
              *
            </Text>
        }
      </FormLabel>

      <InputGroup>
        {leftAddon}
        <ChakraInput
          {...registerProps}
          {...inputStyles}
          autoComplete='off'
          placeholder={placeholder || ''}
          type={type}
        />
        {rightAddon}
      </InputGroup>

      {
        error &&
          <FormErrorMessage variant='photonFormError'>
            {error.message}
          </FormErrorMessage>
      }
    </FormControl>
  )
}

export default Input
