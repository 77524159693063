import moment from 'moment';

import Bugsnag from '@bugsnag/js';
import config from 'config';


export interface CountryItem {
  label: string;
  value: string;
}

const delay = (time: number) => new Promise((res) => setTimeout(res, time));

let countryList: CountryItem[] = [];
const labelMap: Map<string, string> = new Map<string, string>();
const valueMap: Map<string, string> = new Map<string, string>();

let lastUpdated: moment.Moment;
let requestInProgress = false;

const getActualCountryList = async (): Promise<CountryItem[]> => {
  if (requestInProgress) {
    await delay(1000);
    return getActualCountryList();
  }

  if (
    countryList.length
    && lastUpdated
    && moment().diff(lastUpdated, 'hours') < 8
  ) {
    return countryList;
  }

  requestInProgress = true;
  try {
    const response = await fetch(`${config.apiUrl}/services/country-list`);
    countryList = await response.json();
    lastUpdated = moment();

    labelMap.clear();
    valueMap.clear();
    countryList.forEach(
      (country:CountryItem) => {
        labelMap.set(country.label.toLowerCase(), country.value);
        valueMap.set(country.value.toLowerCase(), country.label);
      },
    );
  } catch (e) {
    Bugsnag.notify(e as Error);
    console.error(e);
  } finally {
    requestInProgress = false;
  }

  return countryList;
};

getActualCountryList();

/**
 * Retrieves the value associated with the given label.
 *
 * @param {string} label - The label to search for.
 * @return {string|undefined} The value associated with the given label. Returns undefined if no value is found.
 */
export const getValue = (label: string): string|undefined => labelMap.get(label.toLowerCase());

/**
 * Retrieves the label associated with the provided value.
 * @param {string} value - The value for which to retrieve the label.
 * @return {string|undefined} The label associated with the provided value.
 */
export const getLabel = (value: string): string|undefined => valueMap.get(value.toLowerCase());

/**
 * Retrieves an array of labels from the data.
 *
 * @async
 * @return {Promise<string[]>} Array of labels from the data.
 */
export const getLabels = async (): Promise<string[]> => (await getActualCountryList()).map(
  (country) => country.label,
);

/**
 * Retrieves the values of each country in the data collection.
 *
 * @async
 * @return {Promise<string[]>} An array of values representing each country's value.
 */
export const getValues = async (): Promise<string[]> => (await getActualCountryList()).map(
  (country) => country.value,
);

/**
 * Returns the label map.
 *
 * @async
 * @return {Promise<Map<string, string>>} The label list as a record where the keys are strings and the values are strings.
 */
export const getLabelMap = async (): Promise<Map<string, string>> => {
  await getActualCountryList();
  return labelMap;
};

/**
 * Retrieves the value list from the value map.
 *
 * @return {Map<string, string>} The value list represented as a key-value pair object.
 */
export const getValueList = (): Map<string, string> => valueMap;

/**
 * Retrieves the data of type `CountryItem` from the current object.
 *
 * @async
 * @return {Promise<CountryItem[]>} An array of `CountryItem` objects containing the data.
 */
export const getData = (): Promise<CountryItem[]> => getActualCountryList();

/**
 * Sets the label for a specific value in the CountryList.
 *
 * @async
 * @param {string} value - The value of the country.
 * @param {string} label - The new label to set.
 *
 * @return {Promise<void>}
 */
export const setLabel = async (value: string, label: string): Promise<void> => {
  (await getActualCountryList()).forEach(
    (country) => {
      if (country.value === value) {
        country.label = label;
        valueMap.set(country.value.toLowerCase(), country.label);
      }
    },
  );
};

/**
 * Set the empty option for the CountryList.
 *
 * @async
 * @param {string} label - The label to be displayed for the empty option.
 *
 * @return {Promise<void>}
 */
export const setEmpty = async (label: string): Promise<void> => {
  (await getActualCountryList()).unshift({
    value: '',
    label: label,
  });
  valueMap.set('', label);
  labelMap.set(label, '');
};
