import { IconButton, useClipboard, Tooltip } from '@chakra-ui/react'
import { FaEnvelope } from 'react-icons/fa'
import { FiHelpCircle } from 'react-icons/fi'

interface CopyButtonProps {
  email: string
  label: string
}

function CopyButton ({ email, label }: CopyButtonProps) {
  const { hasCopied, onCopy } = useClipboard(email)

  return (
    <Tooltip label={hasCopied ? 'Copied!' : `Copy ${label}`}>
      <IconButton
        aria-label={hasCopied ? 'Copied!' : `Copy ${label}`}
        icon={label === 'email' ? <FaEnvelope size={30} /> : <FiHelpCircle size={30} />}
        onClick={onCopy}
        color='white'
        bg='transparent'
        _hover={{
          bg: 'transparent'
        }}
      />
    </Tooltip>
  )
}

export default CopyButton
