import React, { useEffect } from 'react'
import {
  useNavigate
} from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  useToast
} from '@chakra-ui/react'

import DefaultAuth from 'layouts/auth/Default'

import illustration from 'assets/img/auth/auth.png'
import Input from '../../../components/form/Input'
import { useForgotPassword } from 'api/user-api'

const schema = z.object({
  email: z.string().email('A valid email address is required')
})

type FormValues = z.infer<typeof schema>

const defaultValues: FormValues = {
  email: ''
}

function ForgotPassword () {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(schema)
  })
  const navigate = useNavigate()

  const textColor = useColorModeValue('navy.700', 'white')
  const buttonVariant = useColorModeValue('photonDark', 'photonLight')
  const textColorSecondary = 'gray.400'
  const toast = useToast()

  const [
    {
      loading,
      error: apiError
    },
    forgotPassword
  ] = useForgotPassword()

  useEffect(
    () => {
      if (apiError) {
        toast(
          {
            title: apiError.response?.data?.error_message || 'Cannot send email. Please contact us.',
            status: 'error',
            isClosable: true
          }
        )
      }
    },
    [apiError]
  )

  const onSubmit = async (data: FormValues) => {
    try {
      await forgotPassword({ data })
      toast({
        title: 'Password reset email sent to your address',
        status: 'success',
        isClosable: true
      })
      navigate('/login', { replace: true })
    } catch (e: any) {
      console.error(e)
    }
  }

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
    >
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Forgot password
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'
          >
            Enter your email!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
          as='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            error={errors.email}
            field='email'
            registerProps={register('email')}
            required
            type='email'
            placeholder='mail@photon.rocks'
            inputStyles={{
              variant: 'auth',
              fontSize: 'sm',
              ms: { base: '0px', md: '0px' },
              mb: '24px',
              size: 'lg'
            }}
          >
            Email
          </Input>

          <Flex justifyContent='right' mb='24px' />
          <Button
            type='submit'
            fontSize='sm'
            variant={buttonVariant}
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            isLoading={loading}
            loadingText='Please wait...'
          >
            Send password reset email
          </Button>
        </Flex>
      </Flex>
    </DefaultAuth>
  )
}

export default ForgotPassword
