import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  InputRightElement,
  Link,
  Text,
  useColorModeValue,
  useToast
} from '@chakra-ui/react'

import { Helmet } from 'react-helmet'
import {
  Link as RouterLink,
  NavLink,
  useNavigate,
  useParams
} from 'react-router-dom'

import {
  useForm
} from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import throttle from 'throttleit'

import DefaultAuth from '../../../layouts/auth/Default'
import {
  useCheckRegToken,
  useRegister
} from '../../../api/user-api'

import config from '../../../config'
import { RiEyeCloseLine } from 'react-icons/ri'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import Input from '../../../components/form/Input'
import Checkbox from '../../../components/fields/Checkbox'
import illustration from '../../../assets/img/auth/auth.png'

const textColorSecondary = 'gray.400'

export const Registration = () => {
  const params = useParams()
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  const [formError, setFormError] = useState(null)
  const textColor = useColorModeValue('navy.700', 'white')

  const schema = z.object({
    newsletter: z.boolean(),
    privacyNotice: z.boolean().refine(
      value => value,
      {
        message: 'Please accept our privacy notice in order to use our services.'
      }
    ),
    acceptsTermsOfUse: z.boolean().refine(
      value => value,
      {
        message: 'Please accept the terms and conditions in order to use our services.'
      }
    ),
    pw: z
      .string()
      .min(8, { message: 'Password required and min 8 chars' })
      .max(50, 'Password required and min 50 chars'),
    passwordAgain: z
      .string()
      .min(8, 'Password required and min 8 chars')
      .max(50, 'Password required and min 50 chars')
  }).refine(
    (data) => data.pw === data.passwordAgain,
    {
      message: "Passwords don't match",
      path: ['passwordAgain']
    }
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm(
    {
      defaultValues: {
        pw: '',
        passwordAgain: '',
        newsletter: false,
        privacyNotice: false,
        acceptsTermsOfUse: false
      },
      mode: 'all',
      resolver: zodResolver(schema)
    })

  const [
    {
      data: checkData,
      loading: checkLoading,
      error: checkError
    }
  ] = useCheckRegToken(params.regToken)

  const [
    {
      loading: submitLoading,
      error: apiError
    },
    executeRegistration
  ] = useRegister()

  useEffect(
    () => {
      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${config.reCaptchaKey}`
      script.async = true
      document.body.appendChild(script)
      return () => {
        document.body.removeChild(script)
        document.getElementsByClassName('grecaptcha-badge')[0]?.remove()
      }
    },
    []
  )

  const onSubmit = (data: { passwordAgain: any }) => {
    // @ts-expect-error
    window.grecaptcha?.ready(async () => {
      // @ts-expect-error
      const token = await window.grecaptcha.execute(config.reCaptchaKey, { action: 'registration' })
      delete data.passwordAgain
      await executeRegistration({
        data: {
          ...data,
          regToken: params.regToken,
          captchaToken: token
        }
      })
      navigate('/login')
    })
  }

  useEffect(
    () => {
      if (apiError) {
        const errorMessage = apiError.response?.data?.error_message ||
          'We couldn\'t finalize your registration. Please contact to our accountants!'

        toast({
          title: errorMessage,
          status: 'error',
          isClosable: true
        })

        setFormError(errorMessage)
      }
    },
    [apiError]
  )

  let checkErrorContent
  if (checkData) {
    if (checkData.reg === 'finished') {
      checkErrorContent = <Text>Your registration is completed. Please log in!</Text>
    } else if (
      checkData.reg === 'not_exists' ||
      checkData.reg === 'timeout'
    ) {
      checkErrorContent = (
        <Text color='red.500' mt={1}>
          Your registration link is expired. Please contact to our accountants!
        </Text>
      )
    }
  }
  if ((checkError || (checkData && checkData.reg !== 'validated')) && !checkErrorContent) {
    checkErrorContent = (
      <Text color='red.500' mt={1}>
        Registration failed. Please contact to our accountants!
      </Text>
    )
  }

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
    >
      <Helmet>
        <title>Confirming registration | Photon Editor</title>
      </Helmet>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection='column'
      >
        <Flex align='center' direction='column' justify='center' textAlign='center'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Confirm Registration
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'
          >
            Provide your new password and confirm registration!
          </Text>
        </Flex>

        <Container maxWidth='sm' pt='40px' pb='40px'>
          <Box flexGrow={1} mt={3} textAlign='center'>
            {
            checkLoading &&
              <Flex align='center' dir='column'>
                <CircularProgress />
              </Flex>
          }
            {
            !checkLoading &&
            (
              checkErrorContent
                ? <>
                  {checkErrorContent}
                  <Divider my={3} />
                  <Link
                    as={RouterLink}
                    to='/login'
                    color={textColorSecondary}
                  >
                    Login
                  </Link>
                  </>
                : <form
                    noValidate
                    onSubmit={throttle(handleSubmit(onSubmit), 500)}
                  >
                  <Input
                    error={errors.pw}
                    required
                    field='password'
                    type={show ? 'text' : 'password'}
                    registerProps={register('pw')}
                    inputStyles={{
                      fontSize: 'sm',
                      mb: '24px',
                      size: 'lg',
                      variant: 'auth'
                    }}
                    rightAddon={
                      <InputRightElement display='flex' alignItems='center' mt='4px'>
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: 'pointer' }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={() => setShow(!show)}
                        />
                      </InputRightElement>
                    }
                  >
                    Password
                  </Input>

                  <Input
                    error={errors.passwordAgain}
                    required
                    field='passwordAgain'
                    type={show ? 'text' : 'password'}
                    registerProps={register('passwordAgain')}
                    inputStyles={{
                      fontSize: 'sm',
                      mb: '24px',
                      size: 'lg',
                      variant: 'auth'
                    }}
                    rightAddon={
                      <InputRightElement display='flex' alignItems='center' mt='4px'>
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: 'pointer' }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={() => setShow(!show)}
                        />
                      </InputRightElement>
                    }
                  >
                    Password Again
                  </Input>

                  <Flex align='start' ml={-1} mt={2}>
                    <Checkbox
                      error={errors.privacyNotice}
                      field='privacyNotice'
                      registerProps={register('privacyNotice')}
                    >
                      <Text fontSize='14px' lineHeight='19px'>
                        I accept the&nbsp;
                        <Link
                          variant='photonLavender'
                          isExternal
                          as={NavLink}
                          to={`${config.appBaseUrl}/privacy-notice`}
                        >
                          Privacy Notice.
                        </Link>
                        <sup>*</sup>
                      </Text>
                    </Checkbox>
                  </Flex>

                  <Flex align='start' ml={-1} mt={2}>
                    <Checkbox
                      error={errors.acceptsTermsOfUse}
                      field='acceptsTermsOfUse'
                      registerProps={register('acceptsTermsOfUse')}
                    >
                      <Text fontSize='14px' lineHeight='19px'>
                        I accept the&nbsp;
                        <Link
                          variant='photonLavender'
                          isExternal
                          as={NavLink}
                          to={`${config.appBaseUrl}/terms-and-conditions`}
                        >
                          Terms & Conditions
                        </Link>
                        <sup>*</sup>
                      </Text>
                    </Checkbox>
                  </Flex>

                  <Flex align='start' ml={-1} mt={2}>
                    <Checkbox
                      error={errors.newsletter}
                      field='newsletter'
                      registerProps={register('newsletter')}
                    >
                      <Text fontSize='14px' lineHeight='19px'>
                        Sign up for the newsletter
                      </Text>
                    </Checkbox>
                  </Flex>

                  {
                    formError &&
                      <Text color='red.500' mt={1}>{formError}</Text>
                  }

                  <Box mt={2}>
                    <Button
                      type='submit'
                      fontSize='sm'
                      variant='photonDark'
                      fontWeight='500'
                      w='100%'
                      h='50'
                      mb='24px'
                      isLoading={submitLoading}
                      loadingText='Please wait...'
                    >
                      Register
                    </Button>
                  </Box>
                  </form>
            )
          }
          </Box>
        </Container>
      </Flex>
    </DefaultAuth>
  )
}
