import {
  Checkbox as ChakraCheckbox,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'

interface InputProps {
  children: ReactNode
  error?: FieldError
  field: string
  inputStyles?: any
  registerProps: UseFormRegisterReturn
}

const Checkbox = ({
  children,
  error,
  field,
  inputStyles,
  registerProps
}: InputProps) => (
  <FormControl id={field} isInvalid={!!error} variant='photonForm'>
    <ChakraCheckbox
      variant='photonCheckbox'
      {...registerProps}
      {...inputStyles}
    >
      {children}
    </ChakraCheckbox>
    {error && (
      <FormErrorMessage variant='photonFormError'>
        {error.message}
      </FormErrorMessage>
    )}
  </FormControl>
)

export default Checkbox
