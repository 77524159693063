export const columnsDataCheck = [
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'PROGRESS',
    accessor: 'progress'
  },
  {
    Header: 'QUANTITY',
    accessor: 'quantity'
  },
  {
    Header: 'DATE',
    accessor: 'date'
  }
]
export const columnsDataComplex = [
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'STATUS',
    accessor: 'status'
  },
  {
    Header: 'DATE',
    accessor: 'date'
  },
  {
    Header: 'PROGRESS',
    accessor: 'progress'
  }
]
