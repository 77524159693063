import { useEffect } from 'react'

import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  useToast,
  Text
} from '@chakra-ui/react'

import ProfileSettings from './components/profileSettings'
import BillingSettings from './components/billingSettings'
import { useGetEditor } from 'api/user-api'

export default function ProfileSettingsPage () {
  const toast = useToast()
  const [
    {
      data: editor,
      loading: getEditorInProgress,
      error: getEditorError
    }
  ] = useGetEditor()

  useEffect(() => {
    if (getEditorError) {
      toast({
        title: 'Error fetching data, we are working on it',
        status: 'error',
        isClosable: true
      })
    }
  }, [getEditorError])

  return (
    <>
      <Text as='b' ml='20px' fontSize='3xl'>Profile</Text>
      <Box mt='20px'>
        <Tabs>
          <TabList ml='20px' mr='20px'>
            <Tab
              border='2px solid'
              borderColor='transparent'
              borderTopRadius='lg'
              borderBottom='none'
              bg='#a6a3ff'
              color='white'
              _selected={{ bg: '#6b52ff', color: 'white' }}
              mr='5px'
            >
              Profile data
            </Tab>
            <Tab
              border='2px solid'
              borderColor='transparent'
              borderTopRadius='lg'
              borderBottom='none'
              bg='#a6a3ff'
              color='white'
              _selected={{ bg: '#6b52ff', color: 'white' }}
              mr='5px'
            >
              Billing info
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {
              getEditorInProgress &&
                <Spinner />
            }
              {
              !getEditorInProgress &&
              editor &&
                <ProfileSettings editor={editor.data} />
            }
            </TabPanel>
            <TabPanel>
              {
              getEditorInProgress &&
                <Spinner />
            }
              {
              !getEditorInProgress &&
              editor &&
                <BillingSettings editor={editor.data.editor} />
            }
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  )
}
