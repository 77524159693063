import {
  Portal,
  Box,
  useDisclosure
} from '@chakra-ui/react'

import Navbar from 'components/navbar/NavbarAdmin'
import Sidebar from 'components/sidebar/Sidebar'
import { SidebarContext } from 'contexts/SidebarContext'
import { useState } from 'react'
import {
  useLocation
} from 'react-router-dom'
import routes from '../routes'

export default function MenuLayout (props: { [x: string]: any }) {
  const { page, ...rest } = props
  // states and functions

  const [fixed] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(false)
  const location = useLocation()

  const getActiveRoute = (routes: RoutesType[]): string => {
    const activeRoute = routes[0].name
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.includes(routes[i].path)) {
        return routes[i].name
      }
    }
    return activeRoute
  }

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    const activeNavbar = false
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.includes(routes[i].path)) {
        return routes[i].secondary
      }
    }
    return activeNavbar
  }

  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    const activeNavbar = false
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.includes(routes[i].path)) {
        return routes[i].name
      }
    }
    return activeNavbar
  }

  document.documentElement.dir = 'ltr'
  const { onOpen } = useDisclosure()
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar
        }}
      >
        <Sidebar routes={routes} display='none' {...rest} />
        <Box
          float='right'
          minHeight='100vh'
          height='100%'
          overflow='auto'
          position='relative'
          maxHeight='100%'
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'
        >

          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText='Photon'
                brandText='Photon'
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>
          <Box mx='auto' mt='100px' p='20px'>
            {page}
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  )
}
