import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route
} from 'react-router-dom'

import DashboardPage from './views/dashboard/DashboardPage'
import SignIn from './views/auth/signIn'
import MenuLayout from './layouts/MenuLayout'
import ProfileSettingsPage from './views/editor/ProfileSettingsPage'
import BillingPage from './views/billing/BillingPage'
import { Registration } from './views/auth/registration'
import ProjectsPage from './views/projects/ProjectsPage'
import ProjectDetailsPage from './views/project/ProjectDetailsPage'
import ForgotPassword from './views/auth/forgotPassword'
import { NewPassword } from './views/auth/newPassword'
import { ProtectedLayout } from './layouts/ProtectedLayout'
import { PolicyAndConditions } from 'views/auth/policyAndConditions'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/intel' element={<ProtectedLayout />}>
        <Route path='dashboard' element={<MenuLayout page={<DashboardPage />} />} />
        <Route path='billing' element={<MenuLayout page={<BillingPage />} />} />
        <Route path='profile-settings' element={<MenuLayout page={<ProfileSettingsPage />} />} />
        <Route path='projects' element={<MenuLayout page={<ProjectsPage />} />} />
        <Route path='project/:projectId' element={<MenuLayout page={<ProjectDetailsPage />} />} />
      </Route>

      <Route path='/registration/:regToken' element={<Registration />} />
      <Route path='/new-password/:regToken' element={<NewPassword />} />
      <Route path='/login' element={<SignIn />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/policy-and-conditions' element={<PolicyAndConditions />} />

      <Route path='*' element={<Navigate to='/intel/projects' replace />} />
    </Route>
  )
)
