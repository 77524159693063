export const columnsDataOngoingProjects = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Client',
    accessor: 'client'
  },
  {
    Header: 'Delivery deadline',
    accessor: 'deliveryDeadline'
  },
  {
    Header: "Photographer's deadline",
    accessor: 'editingDeadline'
  },
  {
    Header: 'Status',
    accessor: 'editingStatus'
  },
  {
    Header: 'Photographer',
    accessor: 'photographer'
  },
  {
    Header: 'Exp. number of photos',
    accessor: 'expectedPhotoNumber'
  },
  {
    Header: 'Pics uploaded by the photographer',
    accessor: 'uploadedPhotosNumber'
  },
  {
    Header: 'Upploaded pics Pics uploaded by me',
    accessor: 'uploadedPhotosByEditorNumber'
  },
  {
    Header: 'Action',
    accessor: 'action'
  }
]

export const columnsDataClosedProjects = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Client',
    accessor: 'client'
  },
  {
    Header: 'Delivery deadline',
    accessor: 'deliveryDeadline'
  },
  {
    Header: 'Editing status',
    accessor: 'editingStatus'
  },
  {
    Header: 'Photographer',
    accessor: 'photographer'
  },
  {
    Header: 'Exp. number of photos',
    accessor: 'expectedPhotoNumber'
  },
  {
    Header: 'Pics uploaded by the photographer',
    accessor: 'uploadedPhotosNumber'
  },
  {
    Header: 'Pics uploaded by me',
    accessor: 'uploadedPhotosByEditorNumber'
  },
  {
    Header: 'Action',
    accessor: 'action'
  }

]
