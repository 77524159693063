// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react'
import Footer from 'components/footer/FooterAdmin'
//   Custom components
import Brand from 'components/sidebar/components/Brand'
import Links from 'components/sidebar/components/Links'
import SidebarCard from 'components/sidebar/components/SidebarCard'

// FUNCTIONS

function SidebarContent (props: { routes: RoutesType[] }) {
  const { routes } = props
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <Stack direction='column' mt='8px' mb='auto'>
        <Box ps='20px' pe={{ lg: '16px', '2xl': '16px' }} mt='100px'>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Footer />
    </Flex>
  )
}

export default SidebarContent
